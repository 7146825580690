/* istanbul ignore file */
import { Datacenter, datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const isTestEnvironment = process.env.NODE_ENV === 'test';
const isProductionEnvironment = process.env.REACT_APP_STAGE === 'prod';

export function initDatadogLogs(): void {
  if (isTestEnvironment) {
    // eslint-disable-next-line no-console
    console.warn('Datadog logs are disabled in test environment');
    return;
  }

  datadogLogs.init({
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN || '',
    datacenter: Datacenter.EU,
    forwardErrorsToLogs: true,
    env: process.env.REACT_APP_STAGE,
    service: `${process.env.REACT_APP_BRAND}_fzportal`,
    sampleRate: 100,
  });

  datadogLogs.setLoggerGlobalContext({
    brand: process.env.REACT_APP_BRAND,
    stage: process.env.REACT_APP_STAGE,
    platform: 'fzportal',
  });
}

export function initDatadogRum() {
  if (!process.env.REACT_APP_DD_RUM_APPLICATION_ID || !process.env.REACT_APP_DD_RUM_CLIENT_TOKEN) {
    // eslint-disable-next-line no-console
    console.warn('Datadog RUM is not initialized. Missing environment variables.');
    return;
  }

  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_RUM_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DD_RUM_CLIENT_TOKEN,
    site: process.env.REACT_APP_DD_RUM_SITE || 'datadoghq.eu',
    service: 'intl-fz-portal',
    env: process.env.REACT_APP_STAGE,
    // TODO: Parametrize and move to LD Flag
    sessionSampleRate: !isProductionEnvironment ? 100 : 0,
    sessionReplaySampleRate: !isProductionEnvironment ? 100 : 0,
    trackUserInteractions: true,
    trackLongTasks: true,
    trackResources: true,
    defaultPrivacyLevel: 'mask',
  });

  datadogRum.setGlobalContext({
    brand: process.env.REACT_APP_BRAND,
    stage: process.env.REACT_APP_STAGE,
  });
}

export function setDatadogRumUser(user: { id: string; email: string }) {
  datadogRum.setUserProperty('id', user.id);
  datadogRum.setUserProperty('email', user.email);
}
